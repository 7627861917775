import { CreateAccountDto } from '@core/models';
import Add from '@mui/icons-material/Add';
import { Switch } from '@mui/joy';
import Button from '@mui/joy/Button';
import DialogContent from '@mui/joy/DialogContent';
import DialogTitle from '@mui/joy/DialogTitle';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useStore } from 'store/StoreContext';

export const AccountModal = observer(() => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { accounts: accountsStore } = useStore();

  const {
    register,
    handleSubmit,
    control,
  } = useForm<CreateAccountDto>();

  const shouldShowisDemoAccountField = new URLSearchParams(window.location.search).get('showdemofield') === 'true';

  const onSubmit: SubmitHandler<CreateAccountDto> = async data => {
    await accountsStore.createAccount(data);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="neutral"
        startDecorator={<Add />}
        onClick={() => setOpen(true)}
      >
        Create new account
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog>
          <DialogTitle>Create new account</DialogTitle>
          <DialogContent>Enter new account information</DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input {...register('name')} required />
              </FormControl>
              <FormControl>
                <FormLabel>Key</FormLabel>
                <Input {...register('key')} required />
              </FormControl>
              <FormControl>
                <FormLabel>Secret</FormLabel>
                <Input {...register('secret')} required />
              </FormControl>
              <FormControl>
                <FormLabel>Is sandbox environment?</FormLabel>
                <Controller
                  name="isSandboxEnvironment"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Switch checked={value} onChange={onChange} />
                  )}
                />
              </FormControl>
              {shouldShowisDemoAccountField && (
                <FormControl>
                  <FormLabel>Is demo environment?</FormLabel>
                  <Controller
                    name="isDemoAccount"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Switch checked={value} onChange={onChange} />
                    )}
                  />
                </FormControl>
              )}
              <Button type="submit">Submit</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
});
