/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CreateAccountDto, UpdateFollowedTradersDto, UpdateTradeSettingsDto } from '@core/models';
import { mapValues, sortBy } from 'lodash';
import { computed } from 'mobx';
import { ExtendedModel, Model, _async, _await, fromSnapshot, idProp, model, modelFlow, prop, tProp, types } from 'mobx-keystone';
import { toast } from 'react-toastify';
import { accountCtx } from 'store/contexts/account';
import { MainModel } from 'store/utils/base-model';
import { error as toastErrorConfig, success as toastSuccessConfig } from '../../../constants/toast-configs';
import { FollowedTrader } from '../leaderboard/followed-trader';

@model('trade-settings')
export class TradeSettings extends ExtendedModel(MainModel, {
  id: idProp,
  tp: prop<number>(),
  sl: prop<number>(),
  leverage: prop<number>(),
  risk: prop<number>(),
  maxRisk: prop<number>(),
  useTraderRisk: prop<boolean>(),
  isTradingDisabled: prop<boolean>(),
  shouldOpen: prop<boolean>(),
  shouldClose: prop<boolean>(),
  shouldIncrease: prop<boolean>(),
  shouldDecrease: prop<boolean>(),
  isUpdating: prop<boolean>(),
  traderId: prop<string>(),
  maxOpenPositionsLimit: prop<number | null>(),
  shouldNotOpenMultipleOnSymbol: prop<boolean>(),
  isSafeMode: prop<boolean>(),
  exceptionList: prop<string[]>(() => ([])),
}) {
  get accountId () {
    const { accountId } = accountCtx.get(this);
    return accountId;
  }

  @modelFlow
  *update(updateTradeSettingsDto: UpdateTradeSettingsDto) {
    this.isUpdating = true;
    try {
      // @ts-ignore
      const payload = mapValues(updateTradeSettingsDto, value => (value === '' ? null : value));
      console.log('payload', payload);
      const tradeSettingsPayload = yield* _await(this.api.update(`account/${this.accountId}/trade-settings/${this.id}`, payload));
      Object.assign(this, tradeSettingsPayload);
      toast('Trader settings updated.', toastSuccessConfig);
    } catch(err) {
      console.log('Update trade settings error', err);
    } finally {
      this.isUpdating = false;
    }
  }

  @modelFlow
  *addException(exception: string) {
    const payload = { exception };
    const tradeSettingsPayload = yield* _await(this.api.create(`account/${this.accountId}/trade-settings/${this.id}/add-exception`, payload));
    Object.assign(this, tradeSettingsPayload);
  }

  @modelFlow
  *removeException(exception: string) {
    const payload = { exception };
    const tradeSettingsPayload = yield* _await(this.api.update(`account/${this.accountId}/trade-settings/${this.id}/remove-exception`, payload));
    Object.assign(this, tradeSettingsPayload);
  }
}

@model('balance-stats')
export class BalanceStats extends Model({
  balance: prop<string>(),
  initialMarginRisk: prop<string>(),
  maxMarginRisk: prop<string>(),
  equity: prop<string>(),
}) {}

@model('account')
export class Account extends ExtendedModel(MainModel, {
  id: idProp,
  name: prop<string>(),
  traders: tProp(types.array(types.model(FollowedTrader)), () => []),
  tradeSettings: tProp(types.array(types.model(TradeSettings))),
  isUpdatingTradeSettings: prop<boolean>(false),
  isUpdatingFollowedTraders: prop<boolean>(false),
  isSandboxEnvironment: prop<boolean>(),
  isDemoAccount: prop<boolean>(),
  balanceStats: tProp(types.maybe(types.model(BalanceStats))),
}) {
  protected onInit(): void {
    this.fetchBalanceStats();
    accountCtx.setComputed(this, () => ({ accountId: this.id }));
  }

  @modelFlow
  *fetchBalanceStats() {
    let payload;
    try {
      payload = yield* _await(this.api.fetch(`account/${this.id}/balance-stats`));
      this.balanceStats = new BalanceStats(payload);
    } catch (err) {
      toast(`Error fetching balance for account ${this.name}.`, toastErrorConfig);
    }
  }

  @modelFlow
  *remove() {
    yield* _await(this.api.delete(`account/${this.id}`));
    this.isDeleted = true;
  }

  @modelFlow
    updateName = _async(function*(this: Account, name: string) {
      try {
        const account = yield* _await(this.api.update(`account/${this.id}`, { name }));
        this.name = account.name;
        toast('Account name updated.', toastSuccessConfig);
        return this.name;
      } catch(err) {
        console.log('Update account name error', err);
        throw err;
      }
    });

  @modelFlow
    createTradeSettings = _async(function*(this: Account, trader?: FollowedTrader) {
      const url = `account/${this.id}/trade-settings${trader ? `/${trader.id}` : ''}`;
      try {
        const tradeSettings = fromSnapshot(yield* _await(this.api.create(url)));
        this.tradeSettings.push(fromSnapshot(tradeSettings));
        toast('New trading settings created.', toastSuccessConfig);
        return tradeSettings;
      } catch(err) {
        console.log('Update trade settings error', err);
        throw err;
      }
    });

  @modelFlow
  *updateFollowedTraders(updateFollowedTradersDto: UpdateFollowedTradersDto) {
    this.isUpdatingFollowedTraders = true;
    try {
      const traders = yield* _await(this.api.update(`account/${this.id}/followed-traders`, updateFollowedTradersDto));
      this.traders = fromSnapshot(traders);
      toast('Followers updated.', toastSuccessConfig);
    } catch(err) {
      console.log('Update trade settings error', err);
    } finally {
      this.isUpdatingFollowedTraders = false;
    }
  }

  @computed
  get followedTraderIds() {
    return this.traders.map(({ id }) => id);
  }

  @computed
  get mainTradeSettings() {
    return this.tradeSettings.find(tradeSettings => tradeSettings.traderId === null);
  }
}

@model('accounts')
export class Accounts extends ExtendedModel(MainModel, {
  accounts: tProp(types.array(types.model(Account)), () => []),
  isInitiallyFetched: tProp(types.boolean, false),
}) {
  @modelFlow
  *fetchAccounts() {
    const accounts = yield* _await(this.api.fetch('account'));
    this.accounts = fromSnapshot(accounts);
    this.isInitiallyFetched = true;
  }

  @modelFlow
  *createAccount(createAccountDto: CreateAccountDto) {
    const account = yield* _await(this.api.create('account', createAccountDto));
    this.accounts.push(fromSnapshot(account));
  }

  findAccountById(selectedId?: string) {
    return this.accounts.find(({ id }) => id === selectedId);
  }

  findAccountAndTradeSettingsById(accountId: string, traderId: string) {
    let payload: { account?: Account; tradeSettings?: TradeSettings } = {};

    const account = this.accounts.find(({ id }) => id === accountId);

    if (!account) {
      return payload;
    }

    payload = { account };

    const tradeSettings = account.tradeSettings.find(tradeSettings => tradeSettings.traderId === traderId);

    if (!tradeSettings) {
      return payload;
    }

    return {
      ...payload,
      tradeSettings,
    };
  }

  @computed
  get sortedAccountList() {
    return sortBy(this.accounts, 'name').filter(({ isDeleted }) => !isDeleted);
  }
}
